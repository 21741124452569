import React from 'react';

interface OrionIconProps {
    icon: string;
    fontSize?: number;
    color?: string;
}

const OrionIcon = (props: OrionIconProps) => {
    return <i className={`o-${props.icon}-1`} style={{ fontSize: props.fontSize, color: props.color }} />;
};

export default OrionIcon;
