import React from 'react';
import Icon from '@ant-design/icons';
import { CustomIconProps } from './types';

const GitHubSvg = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M12.001 3C7.02793 2.99794 3 7.13335 3 12.2375C3 16.2738 5.51972 19.7048 9.02882 20.9649C9.5014 21.0868 9.429 20.7418 9.429 20.5063V18.9054C6.70015 19.2339 6.58954 17.3789 6.40655 17.0691C6.03653 16.4205 5.16177 16.2552 5.42319 15.9454C6.04458 15.6169 6.67802 16.028 7.41202 17.1414C7.94291 17.949 8.97855 17.8127 9.50341 17.6784C9.61803 17.193 9.86337 16.7592 10.2012 16.4225C7.37381 15.902 6.1954 14.1297 6.1954 12.0227C6.1954 11.0002 6.52318 10.0604 7.16669 9.30227C6.75645 8.05256 7.20489 6.98256 7.26522 6.8235C8.43358 6.71609 9.6482 7.68281 9.74271 7.75924C10.4063 7.5754 11.1645 7.47831 12.0131 7.47831C12.8657 7.47831 13.6259 7.57953 14.2955 7.76544C14.5227 7.58779 15.6489 6.7574 16.7348 6.85862C16.7931 7.01767 17.2315 8.06289 16.8454 9.29608C17.4969 10.0562 17.8287 11.0044 17.8287 12.0289C17.8287 14.14 16.6423 15.9144 13.8068 16.4267C14.0497 16.672 14.2425 16.9646 14.3741 17.2873C14.5056 17.6101 14.5733 17.9565 14.573 18.3064V20.6302C14.5891 20.8162 14.573 21 14.8747 21C18.436 19.7668 21 16.311 21 12.2396C21 7.13335 16.9701 3 12.001 3V3Z"
            fill="currentColor"
        />
    </svg>
);

export const GitHubIcon = (props: CustomIconProps) => <Icon component={GitHubSvg} {...props} />;
