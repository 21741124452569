import React from 'react';
import Icon from '@ant-design/icons';
import { CustomIconProps } from './types';

const DiscordSvg = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M20 6C20 6 17.707 4.205 15 4L14.756 4.4885C17.203 5.088 18.326 5.945 19.5 7C17.4765 5.967 15.48 5 12 5C8.52 5 6.5235 5.967 4.5 7C5.674 5.945 7.01 4.992 9.244 4.4885L9 4C6.16 4.2675 4 6 4 6C4 6 1.4395 9.713 1 17C3.58 19.9765 7.5 20 7.5 20L8.32 18.908C6.928 18.424 5.358 17.5605 4 16C5.619 17.225 8.0625 18.5 12 18.5C15.9375 18.5 18.381 17.225 20 16C18.6425 17.5605 17.0725 18.424 15.68 18.908L16.5 20C16.5 20 20.42 19.9765 23 17C22.5605 9.713 20 6 20 6ZM8.75 15C7.783 15 7 14.105 7 13C7 11.895 7.783 11 8.75 11C9.717 11 10.5 11.895 10.5 13C10.5 14.105 9.717 15 8.75 15ZM15.25 15C14.283 15 13.5 14.105 13.5 13C13.5 11.895 14.283 11 15.25 11C16.217 11 17 11.895 17 13C17 14.105 16.217 15 15.25 15Z"
            fill="currentColor"
        />
    </svg>
);

export const DiscordIcon = (props: CustomIconProps) => <Icon component={DiscordSvg} {...props} />;
